// @flow
import * as React from 'react';
import { graphql } from 'gatsby';
import compose from 'recompose/compose';
import withStyles, {
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles';

import NodeBody from '~plugins/prismic/components/NodeBody';
import useGroupedSlicesByType from '~plugins/prismic/hooks/useGroupedSlicesByType';
import withNodePage, {
  type WithNodePageProps,
} from '~plugins/prismic/hocs/withNodePage';
import Header from '~components/Header';
import FooterContactForm from '~components/FooterContactForm';
import useAllNodeBodyComponents from '~hooks/useAllNodeBodyComponents';
import useAppBarActions from '~components/AppBar/hooks/useAppBarActions';
import AuroraAnimationSlice from '~components/slices/AuroraAnimationSlice';
import type { PrismicHomePage } from '~schema';

import { SLICE_TABBED_CARD_GROUPED_NAME } from '../constants';

export type ClassKey = 'root' | 'bodySection';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  ...$Exact<WithNodePageProps<PrismicHomePage>>,
};

const styles = (unusedTheme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    overflow: 'hidden',
  },
  bodySection: {},
});

const nodeBodyComponents = {
  aurora_animation: AuroraAnimationSlice,
  ...useAllNodeBodyComponents(),
};

const HomePage = ({ classes, node }: Props) => {
  const { mergeState } = useAppBarActions();

  React.useLayoutEffect(() => {
    mergeState({ linksColor: 'white' });
  }, []);

  const body = useGroupedSlicesByType({
    slices: node?.data?.body,
    sliceType: 'tabbed_cards',
    groupedType: SLICE_TABBED_CARD_GROUPED_NAME,
  });

  return (
    <div className={classes.root}>
      <Header componentVariant="carousel" items={node?.data?.items} />
      <NodeBody
        node={node}
        body={body}
        components={nodeBodyComponents}
        className={classes.bodySection}
      />
      <FooterContactForm currentPage={node?.data?.name?.text} />
    </div>
  );
};

export default compose(
  withNodePage<PrismicHomePage, *>({
    getNode: data => data?.prismicHomePage,
  }),
  withStyles<ClassKey, *, Props>(styles, { name: 'HomePage' }),
)(HomePage);

export const query = graphql`
  query HomePageQuery($prismicId: ID) {
    prismicHomePage(prismicId: { eq: $prismicId }) {
      id
      lang
      uid
      first_publication_date
      last_publication_date
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        meta_image {
          alt
          url
          dimensions {
            width
            height
          }
        }
        name {
          text
        }
        items {
          item_title {
            text
            html
          }
          item_subtitle {
            text
            html
          }
          item_link_label {
            text
          }
          item_link {
            link_type
            target
            url
          }
          item_background_image {
            alt
            fluid(maxWidth: 1200) {
              ...GatsbyPrismicImageFluid
            }
          }
          overlay_color
          item_background_video {
            link_type
            target
            url
          }
          item_small_background_video {
            link_type
            target
            url
          }
        }
        body {
          ... on Node {
            id
          }
          ... on PrismicHomePageBodyTextWithImage {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_image {
                alt
                fluid(maxWidth: 500) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_video {
                url
                link_type
              }
              slice_background_color
              overlapping_image
              image_position
            }
            items {
              item_highlighted_text {
                text
                html
              }
            }
          }
          ... on PrismicHomePageBodyItemsList {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_image {
                alt
                fluid(maxWidth: 600) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_background_color
              overlapping_image
              image_position
            }
            items {
              item_name {
                text
                html
              }
              item_link {
                link_type
                target
                url
              }
              item_icon {
                alt
                localFile {
                  id
                  childImageSharp {
                    fluid(maxWidth: 50, quality: 85) {
                      ...GatsbyImageSharpFluid_tracedSVG
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomePageBodyHighlightedItems {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_background_color
            }
            items {
              item_title {
                text
                html
              }
              item_description {
                text
                html
              }
              item_icon {
                alt
                localFile {
                  id
                  childImageSharp {
                    fluid(maxWidth: 70, quality: 85) {
                      ...GatsbyImageSharpFluid_tracedSVG
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomePageBodySingleImage {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_image {
                alt
                fluid(maxWidth: 1000) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_caption {
                text
                html
              }
              image_width
            }
          }
          ... on PrismicHomePageBodyImageGrid {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              image_width
              max_line_items
            }
            items {
              item_image {
                alt
                fluid(maxWidth: 500) {
                  ...GatsbyPrismicImageFluid
                }
              }
              item_caption {
                text
                html
              }
            }
          }
          ... on PrismicHomePageBodyVideo {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_link {
                link_type
                url
              }
              video_width
              autoplay
              caption {
                text
                html
              }
              slice_image_placeholder {
                alt
                fluid(maxWidth: 1000) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicHomePageBodyHighlightedContent {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_background_color
              image_position
            }
            items {
              item_title {
                text
                html
              }
              item_label {
                text
              }
              item_link {
                link_type
                target
                url
              }
              item_image {
                alt
                fluid(maxWidth: 500) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicHomePageBodyRelatedContent {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_link_label {
                text
              }
              slice_link {
                link_type
                target
                url
              }
              slice_background_color
            }
            items {
              item_title {
                text
                html
              }
              item_description {
                text
                html
              }
              item_label {
                text
              }
              item_link {
                link_type
                target
                url
              }
              item_image {
                alt
                fluid(maxWidth: 300) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicHomePageBodyBanner {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_overlapping_image {
                alt
                fluid(maxWidth: 400) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_background_image {
                alt
                fluid(maxWidth: 800) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_background_image_color
              slice_background_color
            }
            items {
              item_label {
                text
              }
              item_link {
                link_type
                target
                url
              }
              item_link_style
            }
          }
          ... on PrismicHomePageBodyDownloadForm {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_document {
                link_type
                url
                target
                document {
                  ... on PrismicWhitePaper {
                    id
                    data {
                      document_link {
                        url
                      }
                    }
                  }
                }
              }
              slice_background_color
              zoho_listkey
              zoho_lead_source
              zoho_industry
            }
          }
          ... on PrismicHomePageBodySolutions {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
            }
            items {
              item_title {
                text
                html
              }
              item_description {
                text
                html
              }
              item_link_label {
                text
              }
              item_link {
                link_type
                target
                url
              }
              item_background_image {
                alt
                fluid(maxWidth: 960) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicHomePageBodyLinksGrid {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_background_color
            }
            items {
              item_link_label {
                text
              }
              item_link {
                link_type
                target
                url
              }
            }
          }
          ... on PrismicHomePageBodyTitleAndDescription {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_title_image {
                alt
                fluid(maxWidth: 300) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_background_color
              container_width
            }
          }
          ... on PrismicHomePageBodyTabbedCards {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_tab_name {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_image {
                alt
                fluid(maxWidth: 500) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_background_color
            }
            items {
              item_name {
                text
                html
              }
              item_link {
                link_type
                target
                url
              }
              item_icon {
                alt
                localFile {
                  id
                  childImageSharp {
                    fluid(maxWidth: 50, quality: 85) {
                      ...GatsbyImageSharpFluid_tracedSVG
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomePageBodyArViewer {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_glb_model_link {
                link_type
                url
              }
              select_usdz_model_link {
                link_type
                url
              }
              slice_background_image {
                alt
                fluid(maxWidth: 500) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_background_color
              viewer_button
            }
            items {
              item_title {
                text
                html
              }
              item_description {
                text
                html
              }
              item_icon {
                alt
                localFile {
                  id
                  childImageSharp {
                    fluid(maxWidth: 50, quality: 85) {
                      ...GatsbyImageSharpFluid_tracedSVG
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomePageBodyPrice {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_price {
                text
              }
              slice_image {
                alt
                fluid(maxWidth: 500) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_background_image_color
              slice_background_color
              overlapping_image
            }
            items {
              item_label {
                text
              }
              item_link {
                link_type
                target
                url
              }
              item_link_style
            }
          }
          ... on PrismicHomePageBodyPrices {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_background_color
            }
            items {
              item_title {
                text
                html
              }
              item_description {
                text
                html
              }
              item_price {
                text
              }
              item_label {
                text
              }
              item_link {
                link_type
                target
                url
              }
              item_image {
                alt
                fluid(maxWidth: 300) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicHomePageBodyEmbeddedContent {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_embedded_content {
                text
                html
              }
            }
          }
          ... on PrismicHomePageBodyAnimation {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
            }
          }
          ... on PrismicHomePageBodyCallToAction {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_background_color
            }
            items {
              item_link_label {
                text
              }
              item_link {
                link_type
                target
                url
              }
              item_link_variant
            }
          }
          ... on PrismicHomePageBodyButtonCards {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_background_color
            }
            items {
              item_title {
                text
              }
              item_description {
                text
                html
              }
              item_link {
                link_type
                target
                url
              }
              item_image {
                alt
                fluid(maxWidth: 400) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicHomePageBodyContentGrid {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_background_color
            }
            items {
              item_title {
                text
                html
              }
              item_description {
                text
                html
              }
              item_label {
                text
              }
              item_link {
                link_type
                target
                url
              }
              item_image {
                alt
                fluid(maxWidth: 300) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicHomePageBodyFloatingButton {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_label {
                text
              }
              slice_link {
                link_type
                target
                url
              }
            }
          }
          ... on PrismicHomePageBodyIconsGrid {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              max_line_items
            }
            items {
              item_icon {
                alt
                fluid(maxWidth: 300) {
                  ...GatsbyPrismicImageFluid
                }
              }
              item_name {
                text
                html
              }
              item_link {
                link_type
                target
                url
              }
            }
          }
          ... on PrismicHomePageBodyIconsCarousel {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
            }
            items {
              item_icon {
                alt
                fluid(maxWidth: 300) {
                  ...GatsbyPrismicImageFluid
                }
              }
              item_name {
                text
                html
              }
              item_link {
                link_type
                target
                url
              }
            }
          }
          ... on PrismicHomePageBodyAuroraAnimation {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
            }
          }
        }
      }
    }
  }
`;
